import {
    Box,
    Flex, Image, RadioProps, Text, useRadio, useToken
} from '@chakra-ui/react';
import React from 'react';

import FaceWithSweat from './assets/Face with Sweat.svg';
import GrimacingFace from './assets/Grimacing Face.svg';
import GrinningFace from './assets/Grinning Face.svg';
import SmilingFaceWithSmilingEyes from './assets/Smiling Face with Smiling Eyes.svg';
import SmilingFaceWithSunglasses from './assets/Smiling Face with Sunglasses.svg';

export const FeedbackItem: React.FC<RadioProps & { feedbackType: number } > = ({
    feedbackType, ...radioProps
}) => {
    const {
        state, getInputProps, getRadioProps, htmlProps, getLabelProps
    } = useRadio(radioProps);
    const [primary400]  = useToken('colors', ['primary.400']);
    const feedbackEmojis = [FaceWithSweat, GrimacingFace, SmilingFaceWithSmilingEyes, GrinningFace, SmilingFaceWithSunglasses];

    return (
        <Box
            as={'label'}
            border={{
                base: '1px solid',
                md: 0
            }}
            borderColor={{
                base: state.isChecked ? 'primary.500' : `${primary400}66`,
                md: 'transparent'
            }}
            borderWidth={{
                base: state.isChecked ? '2px' : '1px',
                md: 0
            }}
            w={{
                base: 'auto',
                md: '84px',
                breakTabletLandscape: '90px'
            }}
            p={{
                base: '16px 32px',
                md: 0
            }}
            borderRadius={'full'}
            boxSizing={'border-box'}
            cursor={'pointer'}
            __css={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '-webkit-tap-highlight-color': 'transparent'
            }}
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Flex
                direction={{
                    base: 'row',
                    md: 'column'
                }}
                alignItems={'center'}
            >
                <Flex
                    w={{
                        base: '40px',
                        md: '84px',
                        breakTabletLandscape: '90px'
                    }}
                    h={{
                        base: '40px',
                        md: '84px',
                        breakTabletLandscape: '90px'
                    }}
                    boxSizing={'border-box'}
                    {...getRadioProps()}
                    rounded={'full'}
                    border={'1px solid'}
                    borderColor={{
                        base: 'transparent',
                        md: state.isChecked ? 'primary.500' : `${primary400}66`
                    }}
                    borderWidth={{
                        base: 0,
                        md: state.isChecked ? '2px' : '1px'
                    }}
                    justifyContent={'center'}
                    alignItems={'center'}
                    mb={{
                        base: 0,
                        md: '18px'
                    }}
                    mr={{
                        base: '24px',
                        md: 0
                    }}
                    flexShrink={0}
                >
                    <Image
                        src={feedbackEmojis[feedbackType]}
                        w={{
                            base: '40px',
                            md: '60px'
                        }}
                    />
                </Flex>
                <Text
                    fontSize={{
                        base: '15px',
                        md: '15.24px'
                    }}
                    textAlign={{
                        base: 'left',
                        md: 'center'
                    }}
                    color={'neutral.800'}
                    {...getLabelProps()}
                >
                    {radioProps.children}
                </Text>
            </Flex>
        </Box>
    );
};
